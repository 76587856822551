import { mapActions, mapGetters } from "vuex";
import * as types from "./../../store/types";
import axios from "./../../services/config/main-axios";
import authAxios from "./../../services/config/need-auth-axios";
import bitrahAxios from "./../../services/config/bitrah-axios";

import authService from "../../services/authService";


export default {
    name: "header-comp",
    components: {},
    props: ["headerStyle", "isPanel"],
    data() {
        return {
            loading: false,
            scrollPosition: null,
            queryToken: this.$route.query.token,
            checkIsPayment: true,
        };
    },
    computed: {
        ...mapGetters({
            isAuth: types.GET_IS_AUTH,
            isDarkTheme: types.GET_IS_DARK,
            userInfo: types.GET_USER_INFO,
        }),
    },
    mounted() {
        this.checkPaymentPage();

    },
    methods: {
        ...mapActions({

            checkIsAuth: types.ACTION_CHECK_IS_AUTH,
        }),
        checkPaymentPage() {
            if (this.$route.query.token) {
                this.checkIsPayment = false;
            }

        },
        setLocale(value) {
            axios.defaults.headers.common["Accept-Language"] = value;
            authAxios.defaults.headers.common["Accept-Language"] = value;
            bitrahAxios.defaults.headers.common["Accept-Language"] = value;
            localStorage.setItem("lang", value);
            this.$router.push({
                params: {
                    lang: value,
                },
            });
        },
        logout() {
            this.loading = true;
            authService
                .logout()
                .then(() => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("bitrahAccessToken");
                    localStorage.removeItem("bitrahAdminAccessToken");
                    localStorage.setItem("isAuth", false);
                    this.checkIsAuth();
                    /* this.$store.commit("resetAll"); */
                    this.$router.push(`/${this.$i18n.locale}`);
                })
                // .catch(() => {

                //     this.$bvToast.toast("خطایی در سیستم رخ داده است", {
                //         title: "خطا",
                //         noCloseButton: true,
                //         variant: "custom",
                //         bodyClass:
                //             "dangerBackgroundColor dangerFontColor rtl text-right",
                //         headerClass:
                //             "dangerBackgroundColor dangerFontColor  rtl text-right",
                //         solid: false,
                //     });
                // })
                .finally(() => (this.loading = false));
        },
    },
};
