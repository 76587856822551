import axios from "./config/main-axios";
import authAxios from "./config/need-auth-axios";
import bitrahAxios from "./config/bitrah-axios";

class authService {
    login(username, password) {
        return axios.post("/authentication/login", {
            username: username,
            password: password,
        });
    }

    loginForRegister(username, password) {
        return axios.post("/authentication/registration/login", {
            username: username,
            password: password,
        });
    }

    bitrahLogin(name, username) {
        return bitrahAxios.post("/merchant/register", {
            name: name,
            username: username,
            // name: registerData.name,
            // mobile: registerData.mobile,
            // username: registerData.username,
            // email: registerData.email,
            // national_code: registerData.national_code,
            // category_id: registerData.category_id,
            // business_name: registerData.business_name,
            // is_legal: registerData.is_legal,
            // slug: registerData.slug,
            // address: registerData.address,
            // postal_code: registerData.postal_code,
            // business_national_id: registerData.business_national_id,
            // registration_number: registerData.registration_number,
            // establishment_date:
            //     registerData.date.gy +
            //     "-" +
            //     registerData.date.gm +
            //     "-" +
            //     registerData.date.gd +
            //     " 00:00:00",
            // ceo_name: registerData.ceo_name,
            // logo: registerData.logo,
        });
    }

    bitrahAdminLogin(username) {
        return bitrahAxios.post("/admin/register", {
            username: username,
        });
    }

    register(registerData) {
        return axios.post("/authentication/register", {
            name: registerData.name,
            mobile: registerData.mobile,
            username: registerData.username,
            email: registerData.email,
            national_code: registerData.national_code,
            password: registerData.password,
            category_id: registerData.category_id,
            business_name: registerData.business_name,
            is_legal: registerData.is_legal,
            slug: registerData.slug,
            address: registerData.address,
            postal_code: registerData.postal_code,
            business_national_id: registerData.business_national_id,
            registration_number: registerData.registration_number,
            establishment_date:
                registerData.date.gy +
                "-" +
                registerData.date.gm +
                "-" +
                registerData.date.gd +
                " 00:00:00",
            ceo_name: registerData.ceo_name,
            logo: registerData.logo,
        });
    }

    getRegisterCategories() {
        return bitrahAxios.get("/manifest/board/categories");
    }

    logout() {
        return authAxios.post("/authentication/logout");
    }
}

export default new authService();
